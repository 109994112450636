













































































































































































	import {Prop, Watch} from "vue-property-decorator";
	import {Emit} from "vue-property-decorator";
	import {Component} from '@/shared/component';
	import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
	import {TiersType, Livraison, CubageLivraison} from '@/models';
	import {ObjectHelper} from "@/shared/utils";
	import InputDatePicker from '@/components/Input/DatePicker.vue';
	import storeLivraison from '@/stores/modules/livraison';
	import CrudTiersSearch from '@/components/Crud/Tiers/Search.vue';
    import CrudChauffeurSearch from '@/components/Crud/Chauffeur/Search.vue';
	import CrudCubageLivraisonEditDialog from '@/components/Crud/CubageLivraison/EditDialog.vue';
	import CrudCubageLivraisonEditDialogCreate from '@/components/Crud/CubageLivraison/EditDialogCreate.vue';
	import axios from "axios";
	import {Token} from "@/models";
	import {Stored} from 'vue-stored-prop-decorator'
	import storeToken from "@/stores/modules/token";
	import storeCubageLivraison from '@/stores/modules/cubageLivraison';

	@Component({
		components: {
			InputDatePicker,
			CrudTiersSearch,
			CrudChauffeurSearch,
			storeLivraison,
			CrudCubageLivraisonEditDialog,
			CrudCubageLivraisonEditDialogCreate,
		}
	})
	export default class CrudLivraisonEdit extends AbstractEditVue<Livraison> {
		
		private TiersType = TiersType;
		private savedisabled: Boolean = true;
		private headers2 = [];
		private editCubage: CubageLivraison = null;
		private editNewCubage: CubageLivraison = null;
		@Stored(() => storeToken) private token: Token;
		private storeCubageLivraison = storeCubageLivraison;
	
		public constructor() {
			super(
				storeLivraison,
				'Livraison modifiée',
				'Livraison créé',
			);
			this.headers2 = [
					{text: 'Chantier', align: 'start', value: 'chantier.displayName'},
					{text: 'Bois', value: 'bois'},
					{text: 'Volume', value: 'volume'},
					{text: 'Prix', value: 'prix'},
					{text: 'Actions', value: 'actions2', sortable: false, filterable: false},
				];
		}

		protected async refresh(): Promise<void> {
			this.item = null;
			await super.refresh();

			this.watcher();
		}

		protected async SaveAndRefresh(): Promise<void> {
			await this.onSubmitLocal();
			await this.refresh();
			this.watcher();
		}

		protected async onSubmitLocal(): Promise<void> {
		await this.form.call(async () => {
			//this.$emit('before-submit', this.value);
			const isNew: boolean = !this.value.id;
			if (this.item.id) {
				this.item = await this.currentStore.dispatch('put', this.item);
			} else {
				this.item = await this.currentStore.dispatch('post', this.item);
			}
			this.value = this.item;
			await this.saved();
		
		});
	}

		public watcher(): void {
			if (this.item)
			{
				this.savedisabled = !this.item.chauffeur || !this.item.scierie;
			}
		}

		public async RepartirPrixCamion(): Promise<void> {
			await axios.put(process.env.VUE_APP_BACK_BASE_URL + "/api/livraisons/repartir_prix_camion/" + this.item.id + "/" + this.item.prixCamion + "/" + this.item.prixTransport + "?token=" + this.token.id, {});

			this.refresh();
		}

		private LanceNewCubage()
		{
			storeLivraison.state.CounterCreation = 1;
			this.editNewCubage = new CubageLivraison();

			this.editNewCubage.date = this.item.date;
			this.editNewCubage.livraison = this.item;
			this.editNewCubage.bl = this.item.bl;
			//this.editNewCubage.numfacture = this.item.numfacture;
			this.editNewCubage.scierie = this.item.scierie;
			this.editNewCubage.chauffeur = this.item.chauffeur;
			this.editNewCubage.coef = 0.9;
			this.editNewCubage.coefStere = 0.63;
		}
	}
