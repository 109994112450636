























































































































	import {Prop, Watch} from "vue-property-decorator";
	import {Component, Vue} from '@/shared/component';
	import {Stored} from "vue-stored-prop-decorator";
	import {Chantier, CubageLivraison} from "@/models";
	import {ArrayHelper} from "@/shared/utils";
  	import Checked from '@/components/Checked.vue';
	import {ResultType} from '@/shared/xhttp';

	import storeCubageLivraison, {CubageLivraisonFilter} from '@/stores/modules/cubageLivraison';

	import CrudList from '@/components/Crud/List.vue';
	import TableHead from '@/components/Paginate/TableHead.vue';
	import TableCell from '@/components/Paginate/TableCell.vue';

	@Component({
		components: {
			CrudList,
			TableHead,
			TableCell,
			Checked,
		}
	})
	export default class CrudCubageLivraisonListForSelect extends Vue {

		@Prop({ type: Boolean   , default: true  }) public paginate!: boolean;
		@Prop({ type: Boolean   , default: false }) public dense!   : boolean;

		@Stored(() => storeCubageLivraison) private filters!: CubageLivraisonFilter;
		@Stored(() => storeCubageLivraison)	private cubageLivraisons!: ResultType<CubageLivraison>;
		
		private storeCubageLivraison = storeCubageLivraison;

		public mounted(): void {
			this.watcher();
			this.storeCubageLivraison.state.limit = 200;
		}

		@Watch('chantier')
		public watcher(): void {
		}
	}
