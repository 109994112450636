import Vue from 'vue';
import Vuex, {ActionContext, Store} from 'vuex';
import {AscDesc, ResultType, XHTTPService} from '@/shared/xhttp';
import {
	PaginatorActionWithFilters,
	PaginatorFilterState,
	PaginatorMutationFilter,
	StandardAction
} from '@/shared/utils';
import {Facture, Tiers} from '@/models';
import {FactureService} from '@/xhttp';
import store from '@/stores';
import {NumberFilter} from '@/shared/filter';
import {DateFilter} from '@/shared/filter';

Vue.use(Vuex);

export class FactureFilter {

	public date: DateFilter = new DateFilter();
	public scierie: Tiers[] = [];
	public numfacture: string = '';

	public clear(): void {
		this.date = new DateFilter();
		this.scierie = [];
		this.numfacture = '';
	}

	public toString(): string {
		return JSON.stringify({
			date: this.date,
			scierie: this.scierie.map(p => p.id),
			numfacture: this.numfacture,
		});
	}
}

export class FactureState extends PaginatorFilterState<FactureFilter> {
	public facture: Facture = null;
	public factures: ResultType<Facture> = null;
	public constructor() {
		super(new FactureFilter(), 'factures', 'facture', 'id', AscDesc.DESC, 100);
	}
}

class FactureStore {

	@XHTTPService(() => Facture)
	private factureService: FactureService;
	private  _store: Store<any>;

	public state: FactureState = new FactureState();

	public mutations = {
		...PaginatorMutationFilter(() => this._store, 'factures', 'facture'),
	};

	public actions = {
		...PaginatorActionWithFilters<Facture, FactureState>(() => Facture, 'factures', 'facture'),
		...StandardAction<Facture, FactureState>(() => Facture, 'facture'),

		putMarkPaid(context: ActionContext<FactureState, any>, facture: Facture) {
			return instance.factureService.putMarkPaid(facture);
		},

	};


	public init(store: Store<any>): this {
		this._store = store;
		return this;
	}

}

const instance = new FactureStore();
const storeFacture = new Vuex.Store(instance);
instance.init(storeFacture);

store.registerModule('facture', storeFacture);

export default storeFacture;
