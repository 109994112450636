


















































































	import {Prop, Watch} from "vue-property-decorator";
	import {Component} from '@/shared/component';
	import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
    import {Facture, Tiers, TiersType} from "@/models";
	import InputDatePicker from '@/components/Input/DatePicker.vue';
	import storeFacture from '@/stores/modules/facture';
	import CrudTiersSearch from '@/components/Crud/Tiers/Search.vue';

	@Component({
		components: {
			InputDatePicker,
			CrudTiersSearch,
		}
	})
	export default class CrudFactureEdit extends AbstractEditVue<Facture> {

		//@Prop({ type: Facture, default: null }) facture: Facture;
		private TiersType = TiersType;

		public constructor() {
			super(
				storeFacture,
				'Facture modifiée',
				'Facture créée',
			);
		}

		protected async refresh(): Promise<void> {
			await super.refresh();
		}
	}
