









































































	import {InOut} from 'vue-inout-prop-decorator';
	import {Prop, Watch} from "vue-property-decorator";
	import {Emit} from "vue-property-decorator";
	import {Component} from '@/shared/component';
	import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
	import {TiersType, Livraison, CubageLivraison} from '@/models';
	import {ObjectHelper} from "@/shared/utils";
	import InputDatePicker from '@/components/Input/DatePicker.vue';
	import Checked from '@/components/Checked.vue';

	import storeLivraison from '@/stores/modules/livraison';

	import CrudTiersSearch from '@/components/Crud/Tiers/Search.vue';
    import CrudChauffeurSearch from '@/components/Crud/Chauffeur/Search.vue';
	import CrudCubageLivraisonListForEdit from '@/components/Crud/CubageLivraison/ListForEdit.vue';
	import CrudCubageLivraisonEditDialogCreate from '@/components/Crud/CubageLivraison/EditDialogCreate.vue';

	@Component({
		components: {
			Checked,
			InputDatePicker,
			CrudTiersSearch,
			CrudChauffeurSearch,
			CrudCubageLivraisonListForEdit,
			CrudCubageLivraisonEditDialogCreate,
		}
	})
	export default class CrudLivraisonEditCreate extends AbstractEditVue<Livraison> {
	
		private TiersType = TiersType;
		private savedisabled: Boolean = false;

		public constructor() {
			super(
				storeLivraison,
				'Livraison modifiée',
				'Livraison créé',
			);
		}
	
		protected async refresh(): Promise<void> {
			await super.refresh();

			this.watcher();
		}

		public watcher(): void {
			if (this.item)
			{
				this.savedisabled = !this.item.chauffeur || !this.item.scierie;
			}
		}
}
