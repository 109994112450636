


































import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Emit} from 'vue-property-decorator';
import {Facture} from '@/models';

import CrudFactureShowDialog from '@/components/Crud/Facture/ShowDialog.vue';
import CrudFactureList from '@/components/Crud/Facture/List.vue';
import CrudDeleteDialog from '@/components/Crud/DeleteDialog.vue';

import storeFacture from '@/stores/modules/facture';

@Component({
	components: {
		CrudFactureShowDialog,
		CrudFactureList,
		CrudDeleteDialog,
	}
})
export default class FacturesView extends Vue {
	
	@Stored(() => storeFacture)
	
	private showFacture: Facture = null;
	private editFacture: Facture = null;
	private deletedFacture: Facture = null;
	private storeFacture = storeFacture;
	private isNew: boolean = false;
	
	@Emit()
	private onCreate(): void {
		this.isNew = true;
		var oFacture = new Facture();
		oFacture.date = new Date();
		this.editFacture = oFacture;
	}
	
	private refresh(): void {
		this.isNew = false;
		//storeFacture.dispatch('getC', { filters: true });
	}
}
