


































	import {Component} from '@/shared/component'; 
	import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';

	import storeFacture from '@/stores/modules/facture';

	import InputReglementsClientList from '@/components/Input/ReglementsClientList.vue';
    import {Facture} from "@/models";

	@Component({
		components: {
            InputReglementsClientList,
		}
	})
	export default class CrudFactureEditReglement extends AbstractEditVue<Facture> {
		public constructor() {
			super(
				storeFacture,
				'Réglement modifié',
				'Réglement créé',
			);
		}

	}
