




































































































import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Emit} from 'vue-property-decorator';
import {ResultType} from '@/shared/xhttp';
import {CubageLivraison, Livraison, Facture} from '@/models';

import CrudCubageLivraisonFilter from '@/components/Crud/CubageLivraison/Filter.vue';
import CrudCubageLivraisonEditDialog from '@/components/Crud/CubageLivraison/EditDialog.vue';
import CrudCubageLivraisonEditFactureDialog from '@/components/Crud/CubageLivraison/EditFactureDialog.vue';
import CrudCubageLivraisonList from '@/components/Crud/CubageLivraison/List.vue';
import CrudDeleteDialog from '@/components/Crud/DeleteDialog.vue';
import CrudLivraisonEditDialog from '@/components/Crud/Livraison/EditDialog.vue';
import storeCubageLivraison from '@/stores/modules/cubageLivraison';
import storeFacture from '@/stores/modules/facture';
import storeLivraison from '@/stores/modules/livraison';
import CrudFactureEditDialog from '@/components/Crud/Facture/EditDialog.vue';
import {PaginatorState} from '@/shared/utils';
import axios from "axios";
import {Token} from "@/models";
import storeToken from "@/stores/modules/token";
import storeNotifier, {NotifyInterface} from '@/stores/modules/notifier';

@Component({
	components: {
		CrudCubageLivraisonFilter,
		CrudCubageLivraisonEditDialog,
		CrudCubageLivraisonEditFactureDialog,
		CrudCubageLivraisonList,
		CrudDeleteDialog,
		CrudLivraisonEditDialog,
		storeLivraison,
		CrudFactureEditDialog,
	}
})
export default class CubageLivraisonView extends Vue {

	@Stored(() => storeCubageLivraison)
	private livraisons!: ResultType<CubageLivraison>;

	private editLivraison: Livraison = null;
	private editCubageLivraison: CubageLivraison = null;
	private editFacture: Facture = null;
	
	private deletedLivraison: CubageLivraison = null;
	private storeCubageLivraison = storeCubageLivraison;
	private storeFacture = storeFacture;
	private storeLivraison = storeLivraison;
	@Stored(() => storeToken) private token: Token;

	private refresh(): void {
		storeCubageLivraison.dispatch('getC', { filters: true });
	}

	private async LanceEditLivraison(item): Promise<void> 
	{
		var oNew = new Livraison();
		oNew.id = item.livraison.id;
		this.editLivraison = oNew; // await storeLivraison.dispatch('get', item.livraison.id);
	}

	private LanceEditNumFacture()
	{
		var bSelectionOk :boolean = false;
		this.storeCubageLivraison.state.cubageLivraisons.data.forEach(element => {
			if (element.selected == true)
			{
				bSelectionOk = true;
			}
		});

		if (bSelectionOk !== false)
		{
			var oFacture = new Facture();
			oFacture.date = new Date();
			this.storeCubageLivraison.state.cubageLivraisons.data.forEach(element => {
				// On affecte la nouvelle facture
				if (element.selected == true)
				{
					oFacture.scierie = element.scierie;
				}
			});
			this.editFacture = oFacture;

		}
		else
		{
			this.notify("Aucune sélection");
		}
	}

	private EffacerFiltres()
	{
		storeCubageLivraison.state.filters.clear();
	}

	private SelectAll()
	{
		
		this.storeCubageLivraison.state.cubageLivraisons.data.forEach(element => {
			element.selected = true;
		});
	}

	private SelectNone()
	{
		this.storeCubageLivraison.state.cubageLivraisons.data.forEach(element => {
			element.selected = false;
		});
	}

	private async affecterFacture(): Promise<void> {
		// On va chercher notre facture dans le store
		var oNewFacture = (storeFacture.state as PaginatorState).current; 

		for (const element of this.storeCubageLivraison.state.cubageLivraisons.data)
		{
			if (element.selected == true)
			{
				// On affecte la nouvelle facture
				await axios.put(process.env.VUE_APP_BACK_BASE_URL + "/api/livraisons/" + element.livraison.id + "/putFacture/" + oNewFacture.id + "?token=" + this.token.id, {});
				element.selected = false;
				element.numfacture = oNewFacture.numfacture;
			}
		}
	}
}
