


































































import {Prop, Watch} from "vue-property-decorator";
import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Timeout, WatchObjectProperties} from '@/shared/decorator';
import {Facture, TiersType} from "@/models";

import storeCubageLivraison, {CubageLivraisonFilter} from '@/stores/modules/cubageLivraison';

import InputNumberFilter from '@/components/Input/NumberFilter.vue';
import InputBoisType from '@/components/Input/BoisType.vue';
import InputBoisQuality from '@/components/Input/BoisQuality.vue';
import InputBoisSize from '@/components/Input/BoisSize.vue';
import InputMarquage from '@/components/Input/Marquage.vue';
import {ArrayHelper} from "@/shared/utils";

import InputDateFilter from '@/components/Input/DateFilter.vue';
import CrudTiersSearch from '@/components/Crud/Tiers/Search.vue';
import {InOut} from 'vue-inout-prop-decorator';

@Component({
	components: {
		CrudTiersSearch,
		InputDateFilter,
		InputNumberFilter,
		InputBoisType,
		InputBoisQuality,
		InputBoisSize,
		InputMarquage,
	}
})
export default class CrudCubageLivraisonFilterForSelect extends Vue {
	@InOut({ type: Facture  , isVModel: true }) private value!: Facture;

	@Stored(() => storeCubageLivraison) private page!: number;
	@Stored(() => storeCubageLivraison) private filters!: CubageLivraisonFilter;

	private TiersType = TiersType;

	@WatchObjectProperties('filters', CubageLivraisonFilter)
	@Timeout()
	private filtersWatcher(): void {
		this.page = 0;
		storeCubageLivraison.dispatch('getC', { filters: true });
	}

	public mounted(): void {
		if (this.filters == null)
		{
			this.filters = new CubageLivraisonFilter();
		}

		this.filters.scierie = [ this.value.scierie ];
		storeCubageLivraison.dispatch('getC', { filters: true });
		this.watcher();
	}

	@Watch('chantier')
	public watcher(): void {
	}
}
