

























































































































































import {Component} from '@/shared/component';
import {AbstractShowVue} from '@/components/Abstract/AbstractShowVue';
import Checked from '@/components/Checked.vue';
import {Facture} from '@/models';
import storeFacture from '@/stores/modules/facture';
import CrudTiersShow from '@/components/Crud/Tiers/Show.vue';
import CrudTiersEdit from '@/components/Crud/Tiers/Edit.vue';
import DialogConfirm from '@/components/Dialog/Confirm.vue';
import CrudFactureEditDialog from '@/components/Crud/Facture/EditDialog.vue';
import CrudFactureEditDialogReglements from '@/components/Crud/Facture/EditDialogReglements.vue';
import CrudLivraisonForFactureList from '@/components/Crud/Livraison/ListForFacture.vue';

@Component({
	components: {
		CrudTiersShow,
		CrudTiersEdit,
		Checked,
		DialogConfirm,
		CrudFactureEditDialog,
		CrudFactureEditDialogReglements,
		CrudLivraisonForFactureList,
	}
})
export default class CrudFactureShow extends AbstractShowVue<Facture> {

	private markPayerConfirm: boolean = false;
	private editFacture: Facture = null;
	private editSuiviReglements: Facture = null;

	public constructor() {
		super(storeFacture);
	}

	private async factureMarkPayer(): Promise<void> {
		await storeFacture.dispatch('putMarkPaid', this.item);
		await Promise.all([
			storeFacture.dispatch('get', this.item.id),
		]);
	}
}

