
































import {Emit} from "vue-property-decorator";
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';

import {Facture} from '@/models';

import CrudFactureShow from '@/components/Crud/Facture/Show.vue';

@Component({
	components: {
		CrudFactureShow,
	}
})
export default class CrudFactureShowDialog extends Vue {
	@InOut({ type: Facture  , isVModel: true }) private value!: Facture;
	
	@Emit()
	public onClose() {
		this.value = null;
		
	}
}
