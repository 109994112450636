var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('crud-list',{staticClass:"component-crud-cubageLivraison-list",attrs:{"store":_vm.storeCubageLivraison,"paginate":_vm.paginate,"dense":_vm.dense},scopedSlots:_vm._u([{key:"head",fn:function(){return [_c('table-head',{attrs:{"sort-by":"date"}},[_vm._v("Date")]),_c('table-head',[_vm._v("Scierie/Chauffeur/BL ")]),_c('table-head',{attrs:{"sort-by":"chantier"}},[_vm._v("Chantier ")]),_c('table-head',{attrs:{"sort-by":"boisType"}},[_vm._v("Bois ")]),_c('table-head',{attrs:{"sort-by":"volumeInner"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v("Vol. sous.")])]}}])},[_c('span',[_vm._v("Volume sous écorsse")])])],1)]},proxy:true},{key:"body",fn:function(ref){
var item = ref.item;
return [_c('table-cell',[_vm._v(" "+_vm._s(_vm._f("date")(item.date))+" "),_c('v-checkbox',{model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})],1),_c('table-cell',[(item.scierie)?[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(item.scierie.name.slice(0, 30)+'...'))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.scierie.name))])]),_c('br'),(item.chauffeur)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(item.chauffeur.fullName.slice(0, 30)+'...'))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.chauffeur.fullName))])]):_vm._e(),_c('br'),_vm._v(" "+_vm._s(item.bl)+" ")]:[_c('i',[_vm._v("Indefini")])]],2),_c('table-cell',{staticClass:"chantier"},[(item.chantier)?[_vm._v(" "+_vm._s(item.chantier.displayName)+" "),_c('router-link',{attrs:{"to":{ name: 'chantier-show', params: { id: item.chantier.id } },"no-underline":""}},[(_vm.isGranted('API_GET_CHANTIER'))?_c('v-btn',{attrs:{"icon":"","color":"grey","title":"Afficher"}},[_c('v-icon',[_vm._v("mdi-eye")])],1):_vm._e()],1),_c('br'),_vm._v(" "+_vm._s(item.chantier.commune)+" "),_c('br'),_vm._v(" "+_vm._s(item.chantier.client)+" ")]:[_c('i',[_vm._v("Indefini")])]],2),_c('table-cell',[_vm._v(" "+_vm._s(item.boisType ? item.boisType.name : '')+"-"+_vm._s(item.boisQuality ? item.boisQuality.name : '')+"-"+_vm._s(item.boisSize ? item.boisSize.name : '')+" "),_c('br'),(item.marquage)?_c('v-chip',{attrs:{"color":item.marquage.color,"dark":""}},[_vm._v(" "+_vm._s(item.marquage)+" ")]):_vm._e()],1),_c('table-cell',{attrs:{"numeric":""}},[_c('table',[_c('tr',[_c('td',[(item.volumeApproximatif)?_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-approximately-equal")]):_vm._e()],1),_c('td',[(item.boisSize.grume == true)?_c('span',[_vm._v(" "+_vm._s(item.volumeInner ? item.volumeInner : '')+" m3 ")]):_vm._e(),(item.boisSize.grume !== true)?_c('span',[_vm._v(" "+_vm._s(Number((item.volumeInner) / 0.63).toFixed(2))+" stères "),_c('br'),_vm._v(" ("+_vm._s(item.volumeInner ? item.volumeInner : '0')+" m3) ")]):_vm._e()])])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }